/* =============================================================
 * import
 * ========================================================== */
import $ from 'jquery';
import 'slick-carousel';

/* =============================================================
 * function
 * ========================================================== */

export default function exe() {
  liveSceneSlider();

  window.addEventListener('resize', function() {
    liveSceneSlider();
  });
}

function liveSceneSlider() {
  if ($("#liveSceneSlider").length) {
    $('#liveSceneSlider').not('.slick-initialized').slick({
      autoplay: true,
      speed: 4000,
      autoplaySpeed: 0,
      dots: false,
      swipe: true,
      swipeToSlide: true,
      touchMove: true,
      pauseOnHover: false,
      draggable: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      centerMode: false,
      variableWidth: true,
      arrows: false,
      // cssEase: 'cubic-bezier(.645,.045,.355,1)',
      cssEase: 'linear',
    });
  }
}
