/* =============================================================
 * import
 * ========================================================== */
import Swiper from 'swiper/bundle';
import {gsap} from 'gsap';

/* =============================================================
 * function
 * ========================================================== */

export default function exe() {
  munyumunyuResize();
  munyumunyu();
  couseSwiper();

  window.addEventListener('resize', function() {
    munyumunyuResize();
    couseSwiper();
  });
}


function munyumunyuResize() {
  const shapeAcademyClip = document.getElementById('mask_academies');
  const shapeStudioClip = document.getElementById('mask_studios');
  const windowWidth = window.innerWidth;
  let scales = 1;

  if (windowWidth <= 1280 && windowWidth >= 769) {
    scales = windowWidth / 1280;
  } else if (windowWidth <= 768) {
    scales = windowWidth / 750;
  }
  shapeAcademyClip.style.transform = 'scale(' + scales + ')';
  shapeStudioClip.style.transform = 'scale(' + scales + ')';
}


function munyumunyu() {
  const shapeAcademy = document.getElementById('mask_academy');
  const shapeStudio = document.getElementById('mask_studio');
  const shapeStart = 'M291.8,398.85c36.56-30.52,60.72-82.19,63.76-117.27,3.31-38.19,14.82-76.43,28.81-83.17,15.08-7.27,26.11-4.86,52.89,7.48,2.34,1.18,8.89,4.06,9.72,4.33,34.01,13.15,73.69,7.96,103.96-17.26,45.3-37.79,52.21-106.21,15.39-152.82-36.82-46.56-103.33-53.66-148.68-15.82-18.46,15.39-30.53,35.92-35.88,57.94-3.74,14.49-11.29,38.32-31.05,46.77-19.76,8.45-46.65-7.59-68.86-23.46-63.86-46.24-152.22-45.43-215.82,7.7C-10.56,177.3-22.26,293.12,40.05,372.02c62.3,78.84,175.05,90.87,251.76,26.83h-.01Z';
  const shape2 = 'M280.53,383.44c35.15-29.34,58.37-79.01,61.3-112.74,3.18-36.71,14.25-73.48,27.7-79.96,14.5-6.99,25.1-4.67,50.85,7.19,2.25,1.13,8.55,3.9,9.34,4.16,32.7,12.64,70.84,7.65,99.94-16.59,43.55-36.33,50.19-102.11,14.8-146.91-35.4-44.76-99.34-51.59-142.93-15.21-17.75,14.8-29.35,34.53-34.49,55.7-3.6,13.93-10.85,36.84-29.85,44.96-19,8.12-44.85-7.3-66.2-22.55-61.39-44.45-146.34-43.67-207.48,7.4C-10.15,170.45-21.4,281.79,38.5,357.65c59.89,75.79,168.29,87.36,242.03,25.79h0Z';
  const shape3 = 'M280.53,383.44c35.15-29.34,58.37-79.01,61.3-112.74,3.18-36.71,14.25-73.48,27.7-79.96,14.5-6.99,25.1-4.67,50.85,7.19,2.25,1.13,8.55,3.9,9.34,4.16,32.7,12.64,70.84,7.65,99.94-16.59,43.55-36.33,50.19-102.11,14.8-146.91-35.4-44.76-99.34-51.59-142.93-15.21-17.75,14.8-29.35,34.53-34.49,55.7-3.6,13.93-10.85,36.84-29.85,44.96-19,8.12-44.85-7.3-66.2-22.55-61.39-44.45-146.34-43.67-207.48,7.4C-10.15,170.45-21.4,281.79,38.5,357.65c59.89,75.79,168.29,87.36,242.03,25.79h0Z';
  const shape4 = 'M291.8,398.85c36.56-30.52,60.72-82.19,63.76-117.27,3.31-38.19,14.82-76.43,28.81-83.17,15.08-7.27,26.11-4.86,52.89,7.48,2.34,1.18,8.89,4.06,9.72,4.33,34.01,13.15,73.69,7.96,103.96-17.26,45.3-37.79,52.21-106.21,15.39-152.82-36.82-46.56-103.33-53.66-148.68-15.82-18.46,15.39-30.53,35.92-35.88,57.94-3.74,14.49-11.29,38.32-31.05,46.77-19.76,8.45-46.65-7.59-68.86-23.46-63.86-46.24-152.22-45.43-215.82,7.7C-10.56,177.3-22.26,293.12,40.05,372.02c62.3,78.84,175.05,90.87,251.76,26.83h-.01Z';
  const shape5 = 'M291.8,398.85c36.56-30.52,60.72-82.19,63.76-117.27,3.31-38.19,14.82-76.43,28.81-83.17,15.08-7.27,26.11-4.86,52.89,7.48,2.34,1.18,8.89,4.06,9.72,4.33,34.01,13.15,73.69,7.96,103.96-17.26,45.3-37.79,52.21-106.21,15.39-152.82-36.82-46.56-103.33-53.66-148.68-15.82-18.46,15.39-30.53,35.92-35.88,57.94-3.74,14.49-11.29,38.32-31.05,46.77-19.76,8.45-46.65-7.59-68.86-23.46-63.86-46.24-152.22-45.43-215.82,7.7C-10.56,177.3-22.26,293.12,40.05,372.02c62.3,78.84,175.05,90.87,251.76,26.83h-.01Z';

  // アニメーションを実行
  if (shapeAcademy) {
    const tl = gsap.timeline({
      repeat: -1,
      repeatDelay: 0,
      // repeatRefresh: true,
      // yoyo: true,
      defaults: { // children inherit these defaults
        duration: 2,
        // ease: "none",
        ease: "Sine.easeInOut",
        // ease: "Power1.easeInOut",
      },
      smoothChildTiming: true,
      // autoRemoveChildren: true,
      // onComplete: myFunc,
    });
    tl
      .from(shapeAcademy, {
        attr: {d: shape2},
        duration: 0,
      })
      .to(shapeAcademy, {
        attr: {d: shape2},
        x: 10,
        y: 10,
        scale: .98,
        rotate: 0,
      })
      .to(shapeAcademy, {
        attr: {d: shape3},
        x: 20,
        y: 5,
        duration: 3.2,
      })
      .to(shapeAcademy, {
        attr: {d: shape4},
        x: 10,
        y: 25,
        scale: .96,
        rotate: -2,
        duration: 2.6,
      })
      .to(shapeAcademy, {
        attr: {d: shape5},
        x: 5,
        y: 15,
        duration: 3.9,
      })
      .to(shapeAcademy, {
        x: 2,
        y: 10,
        scale: .98,
        rotate: 1,
        duration: 2.8,
      })
      .to(shapeAcademy, {
        x: 0,
        y: 5,
        duration: 2.8,
      })
      .to(shapeAcademy, {
        attr: {d: shapeStart},
        x: 0,
        y: 0,
        scale: 1.0,
        rotate: 0,
        duration: 3.2,
      })
    ;
  }

  if (shapeStudio) {
    const tl2 = gsap.timeline({
      repeat: -1,
      repeatDelay: 0,
      // repeatRefresh: true,
      // yoyo: true,
      defaults: { // children inherit these defaults
        duration: 2.5,
        // ease: "none",
        ease: "Sine.easeInOut",
        // ease: "Power1.easeInOut",
      },
      smoothChildTiming: true,
      // autoRemoveChildren: true,
      // onComplete: myFunc,
    });
    tl2
      .set(shapeStudio, {
        x: 22,
        y: 22,
        scale: 1,
        rotate: 0,
      })
      .from(shapeStudio, {
        duration: 0,
        x: 22,
        y: 22,
      })
      .to(shapeStudio, {
        x: 26,
        y: 27,
        scale: .94,
        rotate: 0.7,
        duration: 2,
      })
      .to(shapeStudio, {
        x: 24,
        y: 20,
        duration: 2.5,
      })
      .to(shapeStudio, {
        x: 29,
        y: 22,
        scale: .99,
        rotate: -1.8,
        duration: 3.2,
      })
      .to(shapeStudio, {
        x: 19,
        y: 15,
        duration: 3.2,
      })
      .to(shapeStudio, {
        x: 27,
        y: 16,
        scale: .96,
        rotate: -1,
        duration: 2.4,
      })
      .to(shapeStudio, {
        x: 22,
        y: 14,
        duration: 2.9,
      })
      .to(shapeStudio, {
        x: 22,
        y: 22,
        scale: 1.0,
        rotate: 0,
        duration: 3.5,
      })
    ;
  }


  /*

    gsap.fromTo(shape,
      // 赤帯の動き（下から出現）
      {
        webkitClipPath: 'inset(100% 0% 0% 0%)',
        clipPath: 'inset(100% 0% 0% 0%)',
      }, {
        webkitClipPath: 'inset(0% 0% 0% 0%)',
        clipPath: 'inset(0% 0% 0% 0%)',
        duration: .4,
        ease: CustomEase.create("custom", ".32, .95, .6, 1"),
      });
  */
}


export function couseSwiper() {

  const swiper = new Swiper('.swiper', { // eslint-disable-line @typescript-eslint/no-unused-vars
    // modules: [Navigation, Autoplay, Scrollbar],
    slidesPerView: 'auto', // コンテナ内に表示させるスライド数（CSSでサイズ指定する場合は 'auto'）
    speed: 1200, // スライドアニメーションのスピード（ミリ秒）

    // cssMode: true,              // true: CSS Scroll Snap APIが適用
    // mousewheel: true,           // true: マウスホイールでの操作を許可
    // keyboard: true,             // true: キーボードでの操作を許可
    grabCursor: true,           // PCでマウスカーソルを「掴む」マークにする
    watchSlidesProgress: true,  // スライドの進行状況を監視する

    /*
    autoplay: { // 自動再生させる
      delay: 3000, // 次のスライドに切り替わるまでの時間（ミリ秒）
      disableOnInteraction: true, // ユーザーが操作しても自動再生を止めない
      waitForTransition: true, // アニメーションの間も自動再生を止めない（最初のスライドの表示時間を揃えたいときに）
    },
    loop: true,  // 無限ループさせる
    loopAdditionalSlides: 1, // 無限ループさせる場合に複製するスライド数
    freeModeSticky: true,
    */

    freeMode: {
      enabled: true, // true：自由にドラッグ（スワイプ）できるようにする。スライド位置がスナップしなくなる
      momentum: true, // false：ドラッグ（スワイプ）した後の慣性スクロールをオフにする
      momentumRatio: .1, // 数字が大きいほど惰性スクロールの距離が伸びる（freeMode: trueとfreeModeMomentum:trueが前提）
      modeMomentumVelocityRatio: .2, // 数字が大きいほど惰性スクロールのスピードが速くなる（freeMode: trueとfreeModeMomentum:trueが前提）
      sticky: true,
    },

    preventClicks: true, // true: スワイプ中の不要なクリックを無効化
    preventClicksPropagation: true, // true: スワイプ中の不要なクリックイベントを無効化

    navigation: {
      nextEl: '.swiper-button-next', // 「次へ」ボタン要素のクラス
      prevEl: '.swiper-button-prev', // 「前へ」ボタン要素のクラス
    },

    scrollbar: {
      el: '.swiper-scrollbar', // スクロールバー要素のクラス
      draggable: true,
    },
  });

  // return mySwiper;
}
