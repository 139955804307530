/* =============================================================
 * import
 * ========================================================== */
// import * as scrollObserver from './lib.scrollObserver';
// import {mediaQuery} from './jquery.common';
import $ from 'jquery';
import {gsap} from "gsap/gsap-core";
// import bowser from 'bowser';
// const browser = bowser.getParser(window.navigator.userAgent);


/* =============================================================
 * export function
 * ========================================================== */
export function before() {

  const $body = document.body;
  // const path = location.pathname;

  $body.classList.remove('top_page');
  $body.classList.add('sub_page');
  // globalNavi(path);
}

/*
export function cursor() {

  if (!browser.is('mobile') && !browser.is('tablet')) {

    const cursor = document.getElementById('cursor');

    // leader
    const leaderID = 'cursor_leader';
    if (document.getElementById(leaderID) == null) {
      const leader = document.createElement('span');
      leader.id = leaderID;
      leader.className = leaderID;
      cursor.appendChild(leader);
    }

    // leader_filled
    const filledID = 'cursor_filled';
    if (document.getElementById(filledID) == null) {
      const filled = document.createElement('span');
      filled.id = filledID;
      filled.className = filledID;
      cursor.appendChild(filled);
    }

    document.addEventListener('mousemove', function(e) {

      // 擬似カーソルの位置調整
      cursor.style.transform = 'translate(' + e.clientX + 'px, ' + e.clientY + 'px)';

    });
  }
}

export function cursorAnimation() {

  if (!browser.is('mobile') && !browser.is('tablet')) {
    const cursor = document.getElementById('cursor');
    const linkElem = document.querySelectorAll('a:not(.cursor_circle), .cursor_dot');
    for (let i = 0; i < linkElem.length; i++) {
      linkElem[i].addEventListener('mouseover', function() {
        cursor.classList.add('active');
      });
      linkElem[i].addEventListener('mouseout', function() {
        cursor.classList.remove('active');
      });
    }

    const linkElemCircle = document.querySelectorAll('.cursor_circle');
    for (let i = 0; i < linkElemCircle.length; i++) {
      linkElemCircle[i].addEventListener('mouseover', function() {
        cursor.classList.add('active_circle');
      });
      linkElemCircle[i].addEventListener('mouseout', function() {
        cursor.classList.remove('active_circle');
      });
    }
  }
}
*/

export function after() {
  slideDownBtn();
  toTop();
  munyumunyuGourd();
}


/* =============================================================
 * function
 * ========================================================== */
function slideDownBtn() {
  const btn = $("#slideDownBtn");
  const area = $("#slideDownArea");
  if (btn.length) {
    btn.click(function() {
      btn.toggleClass('open');
      area.toggleClass('open').slideToggle();
    });
  }
}


function toTop() {
  const btn = $("#totop");
  if (btn.length) {
    btn.click(function() {
      $("html, body").animate({
        scrollTop: 0,
      }, 600, "swing");
      return false;
    });
  }
}

function munyumunyuGourd() {
  const shapeGourd = document.querySelectorAll('.anim_gourd_path');
  const svgNote = document.querySelectorAll('.svg_gourd_note');
  const circleTxt = document.querySelectorAll('.offer_gourd_circle_txt');
  const shapeStart = 'M157.35,333.91c46.5,2.58,93.5-14.86,126.52-47.75,18.82-16.05,46.22-33.1,59.98-28.84,22.29,6.88,25.57,29.52,35.92,47.25,15.39,30.19,45.91,51.61,82.21,53.66,54.35,3.04,101-38.6,104.16-93.04,3.13-54.4-38.36-100.93-92.78-103.97-22.15-1.24-43.03,4.98-60.2,16.41-11.45,7.38-31.24,18.67-50.03,11.94-18.79-6.73-27.5-34.39-33.08-58.99C314.35,59.41,252.97,4.48,176.62,.26,84.62-4.8,5.65,65.7,.28,157.83c-5.33,92.1,65,170.96,157.07,176.08Z';
  const shape1 = 'M157.35,333.91c46.5,2.58,93.5-14.86,126.52-47.75,18.82-16.05,46.22-33.1,59.98-28.84,22.29,6.88,13.44,25.7,23.79,43.43,15.39,30.19,45.91,51.61,82.21,53.66,54.35,3.04,101-38.6,104.16-93.04,3.13-54.4-38.36-100.93-92.78-103.97-22.15-1.24-43.03,4.98-60.2,16.41-11.45,7.38-33.18,27.64-51.96,20.91-18.79-6.73-13.43-39.54-19.02-64.15C314.35,59.41,252.97,4.48,176.62,.26,84.62-4.8,5.65,65.7,.28,157.83c-5.33,92.1,65,170.96,157.07,176.08Z';
  const shape2 = 'M157.35,333.91c46.5,2.58,93.5-14.86,126.52-47.75,18.82-16.05,46.22-33.1,59.98-28.84,22.29,6.88,27.61,41.34,37.96,59.06,15.39,30.19,53.02,50.44,89.32,52.48,54.35,3.04,101-38.6,104.16-93.04,3.13-54.4-45.47-99.76-99.89-102.79-22.15-1.24-43.03,4.98-60.2,16.41-11.45,7.38-33.28,6.85-52.07,.12-18.79-6.73-27.5-34.39-33.08-58.99C314.35,59.41,252.97,4.48,176.62,.26,84.62-4.8,5.65,65.7,.28,157.83c-5.33,92.1,65,170.96,157.07,176.08Z';
  const shape3 = 'M157.35,333.91c46.5,2.58,93.5-14.86,126.52-47.75,18.82-16.05,53.88-42.44,67.64-38.18,22.29,6.88,28.13,21.45,38.47,39.18,15.39,30.19,45.91,51.61,82.21,53.66,54.35,3.04,101.93-31.95,105.09-86.38,3.13-54.4-38.36-100.93-92.78-103.97-22.15-1.24-43.03,4.98-60.2,16.41-11.45,7.38-42.39,29.43-61.18,22.7-18.79-6.73-27.5-34.39-33.08-58.99C314.35,59.41,252.97,4.48,176.62,.26,84.62-4.8,5.65,65.7,.28,157.83c-5.33,92.1,65,170.96,157.07,176.08Z';
  const shape4 = 'M157.35,333.91c46.5,2.58,93.5-14.86,126.52-47.75,18.82-16.05,42.64-24.36,56.41-20.1,22.29,6.88,38.3,12.86,48.64,30.58,15.39,30.19,45.91,51.61,82.21,53.66,54.35,3.04,101-38.6,104.16-93.04,3.13-54.4-38.36-100.93-92.78-103.97-22.15-1.24-43.03,4.98-60.2,16.41-11.45,7.38-43.97,35.33-62.76,28.6-18.79-6.73-23.93-43.12-29.51-67.73C314.35,59.41,252.97,4.48,176.62,.26,84.62-4.8,5.65,65.7,.28,157.83c-5.33,92.1,65,170.96,157.07,176.08Z';

  if (shapeGourd.length > 0) {
    const tl = gsap.timeline({
      repeat: -1,
      repeatDelay: 0,
      // repeatRefresh: true,
      // yoyo: true,
      defaults: { // children inherit these defaults
        duration: 2,
        // ease: "none",
        ease: "Sine.easeInOut",
        // ease: "Power1.easeInOut",
      },
      smoothChildTiming: true,
      // autoRemoveChildren: true,
      // onComplete: myFunc,
    });
    tl
      .from(shapeGourd, {
        attr: {d: shapeStart},
        duration: 0,
      })
      .to(shapeGourd, {
        x: 10,
        y: 10,
        scale: .98,
        rotate: 0,
      })
      .to(shapeGourd, {
        attr: {d: shape1},
        x: 20,
        y: 5,
        duration: 3.2,
      })
      .to(shapeGourd, {
        attr: {d: shapeStart},
        x: 10,
        y: 25,
        scale: .96,
        rotate: -2,
        duration: 2.6,
      })
      .to(shapeGourd, {
        attr: {d: shape2},
        x: 5,
        y: 15,
        duration: 3.9,
      })
      .to(shapeGourd, {
        attr: {d: shape3},
        x: 2,
        y: 10,
        scale: .98,
        rotate: 1,
        duration: 2.8,
      })
      .to(shapeGourd, {
        attr: {d: shape4},
        x: 0,
        y: 5,
        duration: 2.8,
      })
      .to(shapeGourd, {
        attr: {d: shapeStart},
        x: 0,
        y: 0,
        scale: 1.0,
        rotate: 0,
        duration: 3.2,
      })
    ;
  }

  if (svgNote.length > 0) {
    const tlNote = gsap.timeline({
      repeat: -1,
      repeatDelay: 0,
      // repeatRefresh: true,
      // yoyo: true,
      defaults: { // children inherit these defaults
        duration: 4.5,
        // ease: "none",
        ease: "Sine.easeInOut",
        // ease: "Power1.easeInOut",
      },
      smoothChildTiming: true,
      // autoRemoveChildren: true,
      // onComplete: myFunc,
    });
    tlNote
      .from(svgNote, {
        duration: 0,
      })
      .to(svgNote, {
        x: 0,
        y: 0,
        rotate: 0,
      })
      .to(svgNote, {
        x: -2,
        y: -3,
        rotate: 2,
        // duration: 2.8,
      })
      .to(svgNote, {
        x: 3,
        y: 2,
        rotate: -8,
        // duration: 2.8,
      })
      .to(svgNote, {
        x: 0,
        y: 0,
        rotate: 0,
        duration: 3.2,
      })
    ;
  }

  if (circleTxt.length > 0) {
    gsap.to(circleTxt, {
      repeat: -1,
      rotate: 360,
      duration: 50,
      ease: "none",
    });
  }

}
