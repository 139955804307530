/* =============================================================
 * import
 * ========================================================== */
import $ from 'jquery';
import Player from '@vimeo/player';


/* =============================================================
 * mediaQuery
 * レスポンシブ判定
 * ========================================================== */
export function mediaQuery(windowWidth) {
  if (windowWidth <= 768) {
    return 'sp';
  } else {
    return 'pc';
  }
}


// =============================================================
// smoothScroll
// スムーズなスクロールの処理
// =============================================================
export function smoothScroll(hash) {
  // 調整値の計算
  const header = document.getElementById('header');
  const adjuster = 0;

  if (hash.length) {
    const elem = document.getElementById(hash);
    const rect = elem.getBoundingClientRect();
    const elemtop = rect.top + window.pageYOffset;
    const position = elemtop - header.clientHeight - adjuster;
    $('html, body').animate({scrollTop: position}, 550, 'swing');
  }

  return false;
}


// =============================================================
// モーダルウィンドウ
// =============================================================
export function modalWindow() {

  if ($('#vimeo_video').length) {
    const player = new Player('vimeo_video', {
      url: 'https://vimeo.com/***/***',
      // id: ***,
      width: 640,
    });

    $('.modal').each(function() {
      $(this).on('click', function() {
        const target = $(this).attr('data-target');
        const modal = document.getElementById(target);
        $(modal).fadeIn().css('display', 'flex');
        player.play().then(function() {
          console.log('再生');
        });
        return false;
      });
    });

    // 閉じるボタンを押した時の処理
    $('.modal_close').on('click', function() {
      $('.modal_area').fadeOut();
      // 閉じるボタンを押すとvimeo動画停止
      player.pause().then(function() {
        console.log('一時停止');
      });
      return false;
    });
  }

  if ($('#searchBtn').length) {
    $('#searchBtn').on('click', function() {
      const modal = $('#searchArea');
      const input = $('#s');
      $(modal).fadeIn().css('display', 'flex');
      $(input).focus();
      return false;
    });
    $('#searchAreaBg').on('click', function() {
      const modal = $('#searchArea');
      $(modal).fadeOut();
      return false;
    });
  }

}
