/* =============================================================
 * import
 * ========================================================== */
import barba from '@barba/core';
// import barbaPrefetch from '@barba/prefetch';
import {gsap} from 'gsap';
import * as barbaOpt from './options';
import * as scrollObserver from '../modules/scrollObserver';
import * as routeCommon from '../route/common';
import top from '../route/top';
import news from '../route/news';
import live from '../route/live';
import course from '../route/course';
import contact from '../route/contact';
import {globalNavi} from '../modules/gnav';
import {modalWindow} from '../modules/common';
import {CustomEase} from "gsap/CustomEase";

/* =============================================================
 * variables
 * ========================================================== */

// アニメーション用の変数定義
const $body = document.body;
const domain = document.domain;
const tt = document.querySelectorAll('.tt');
const $header = document.getElementById('header');
const tl = gsap.timeline();

export default function barbaRun() {

  // barba.use(barbaPrefetch);
  barba.init({
    debug: true,
    logLevel: 'debug',
    schema: {
      prefix: 'data-trans',
      wrapper: 'wrapper',
      container: 'container',
      namespace: 'namespace',
    },
    prevent: ({el, event}) => barbaOpt.customPreventCheck(el, event),
    // キャッシュを無効にするページ指定
    cacheIgnore: [
      '/contact/',
      '/contact/confirmation/',
      '/contact/thanks/',
    ],
    views: [
      {
        namespace: 'home',
        beforeEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
        },
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          // routeTop.sliders();
          top();
        },
      },
      {
        namespace: 'news',
        beforeEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
        },
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          news();
        },
      },
      {
        namespace: 'course',
        beforeEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
        },
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          course();
        },
      },
      {
        namespace: 'live',
        beforeEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
        },
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          live();
        },
      },
      {
        namespace: 'contact',
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          contact();
        },
      },
      {
        namespace: 'confirmation',
        afterEnter(data) { // eslint-disable-line @typescript-eslint/no-unused-vars
          contact();
        },
      },
    ],

    transitions: [
      {
        // 全ページ共通のトランジション
        name: 'global-transitions',
        // sync: true, //同時実行モード
        // async before() {},
        // async beforeLeave() {},
        async leave(data) {
          let transitionBackgroundColor;
          if (data.next.container.classList.contains('studio')){
            // transitionBackgroundColor = '#f286ce';
            transitionBackgroundColor = '#faf9fa';
          } else if (data.next.container.classList.contains('academy')) {
            // transitionBackgroundColor = '#1ab3ff';
            transitionBackgroundColor = '#faf9fa';
          } else {
            // transitionBackgroundColor = '#df3481';
            transitionBackgroundColor = '#faf9fa';
          }

          barbaOpt.resetAnyFunc(data); // 各イベントトリガーのリセット
          ttStart();



          gsap.to(tt, {
            startAt: {
              // width: 0,
              width: '100%',
              height: '100%',
              opacity: 1,
              xPercent: 0,
              zIndex: 100,
              borderRadius: "100%",
              webkitClipPath: 'circle(0% at 50vw 50vh)',
              clipPath: 'circle(0% at 50vw 50vh)',
              backgroundColor: '#faf9fa',
            },
            opacity: 1,
            borderRadius: "0",
            backgroundColor: transitionBackgroundColor,
            webkitClipPath: 'circle(100% at 50vw 50vh)',
            clipPath: 'circle(100% at 50vw 50vh)',
            ease: CustomEase.create("custom", ".645, .045, .255, 1"),
            duration: 1.2,
          });
          await new Promise((resolve) => {
            return setTimeout(resolve, 500);
          });
        },
        // async afterLeave() {},
        async beforeEnter(data) {
          globalNavi(data.next.url.path);
          modalWindow();
          gsap.to(tt, {
            duration: 0,
            // backgroundColor: '#F286CE',
          });
          await new Promise((resolve) => {
            return setTimeout(resolve, 10);
          });
        },
        // async enter() {},
        async afterEnter() {
          scrollObserver.floating();
        },
        async after(data) {
          ttEnd();
          setTimeout(() => {
            tl
              .to(tt, {
                zIndex: -1,
              })
              .fromTo(data.next.container, {
                webkitClipPath: 'circle(0% at 50vw 50vh)',
                clipPath: 'circle(0% at 50vw 50vh)',
                borderRadius: "50%",
              }, {
                webkitClipPath: 'circle(100% at 50vw 50vh)',
                clipPath: 'circle(100% at 50vw 50vh)',
                ease: CustomEase.create("custom", ".645, .045, .255, 1"),
                borderRadius: "0",
                delay: 0,
                duration: 1.2,
                /*
                * clipPathのpolygon内の数値に関して
                * 計算後の値で入れるとアニメーションにならないため、各単位ごとに結果を記述する。
                * X: (0% 0%, 100% 0%, 0% 100%, 0% 100%)
                * O: (calc(0vw - 0px) 0%, 100% 0%, calc(100vw - 0vh) 100%, calc(0vw - 0vh - 0px) 100%)
                * 単位省略もアニメーションにならないため、単位も揃えて記述する。
                *
                * Safari等一部旧式のブラウザでも動かすために、webkitClipPathも追記する。
                *  */
              })
              .set(data.next.container, {
                webkitClipPath: 'none',
                clipPath: 'none',
              });

            /*
            gsap.to(tt, {
              duration: .5,
              backgroundColor: '#F286CE',
              // xPercent: 100,
              webkitClipPath: 'circle(0% at 50% 50%)',
              clipPath: 'circle(0% at 50% 50%)',
            });
            */
          }, 0);
        },
      },
      {
        // 各ルールに応じた個別トランジションの設定
        name: 'custom-transitions',
        to: { // どのページへの遷移か
          namespace: [
            'company',
          ],
        },
        from: { // どのページからの遷移か
          namespace: [
            'home',
          ],
        },

        async leave(data) {
          console.log(data);
        },

        async after(data) {
          console.log(data);
        },
      },
    ],

  });

  barba.hooks.beforeEnter(({next}) => {
    barbaOpt.replaceHeadTags(next);
    barbaOpt.scrollAdjuster();
    // routeCommon.cursor();
    // routeCommon.cursorAnimation();
    if (domain === 'mmusicstudio.com') {
      const gaArg = {
        type: 'gtag',
        path: next.url.path,
        account: [
          'G-XZG93YQ6ZM',
          'UA-49932728-1',
        ],
      };
      barbaOpt.gaSend(gaArg);
    }
    // barbaOpt.snsBtnReload(next.url.href);
    routeCommon.before(next);
  });
  barba.hooks.afterEnter(({next}) => {
    // document.getElementById('cursor').classList.remove('active');
    routeCommon.after(next);
  });
  barbaOpt.samePageNotReload();

  /*
   * Transitions
   *  beforeOnce, once, afterOnce, before,
   *  beforeLeave, leave, afterLeave, afterLeave,
   *  enter, afterEnter, after
   * Views
   *  beforeLeave, afterLeave, beforeEnter, afterEnter
   * */

}

// トランジション開始時の設定
function ttStart() {
  $body.classList.remove('loaded');
  $body.classList.add('loading');
  $header.classList.remove('scrolled');
  $header.style.pointerEvents = 'none';
}

// トランジション終了時の設定
function ttEnd() {
  $body.classList.remove('loading');
  $body.classList.add('loaded');
  $header.style.pointerEvents = 'auto';
}

