/* =============================================================
 * import
 * ========================================================== */
import ScrollHint from 'scroll-hint';

/* =============================================================
 * function
 * ========================================================== */

export default function exe() {
  tableScrollHint();

  window.addEventListener('resize', function() {
    tableScrollHint();
  });
}

function tableScrollHint() {
  // wrap table settings
  const contentsArea = document.querySelectorAll(".contents_post table");
  if (contentsArea !== null) {
    const tableAry = Array.prototype.slice.call(contentsArea);
    tableAry.forEach(function(e) {
      e.outerHTML = '<div class="table_wrap">' + e.outerHTML + '</div>';
    });
  }

  // scrollableJS settings
  const scrollableTrigger = document.querySelectorAll(".table_wrap");
  if (scrollableTrigger !== null) {
    const scrollableAry = Array.prototype.slice.call(scrollableTrigger);
    scrollableAry.forEach(function() {
      new ScrollHint(scrollableAry, {
        scrollHintIconAppendClass: 'scroll-hint-icon-white',
        // applyToParents: true,
        i18n: {
          scrollable: 'スクロールできます',
        },
      });
    });
  }
}
